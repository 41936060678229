import {
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  TOGGLE_LOADING_REPORTS,
  DELETE_REPORT,
} from "./actionTypes"
import { tableDefaultState } from "../common/misc.js"

const INIT_STATE = {
  tableData: tableDefaultState,
  error: {},
  loading: false,
}

const reports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        tableData: action.payload,
      }

    case GET_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case TOGGLE_LOADING_REPORTS:
      return {
        ...state,
        loading: !state.loading,
      }

    case DELETE_REPORT:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          data: state.tableData.data.filter((item) => item._id !== action.payload),
        },
      }

    default:
      return state
  }
}

export default reports
