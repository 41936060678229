import {
  GET_REPORTS,
  GET_REPORTS_FAIL,
  GET_REPORTS_SUCCESS,
  TOGGLE_LOADING_REPORTS,
  DELETE_REPORT,
} from "./actionTypes"

export const getReports = (params) => ({
  type: GET_REPORTS,
  payload: params,
})

export const getReportsSuccess = reports => ({
  type: GET_REPORTS_SUCCESS,
  payload: reports,
})

export const getReportsFail = error => ({
  type: GET_REPORTS_FAIL,
  payload: error,
})

export const toggleLoadingReports = () => ({
  type: TOGGLE_LOADING_REPORTS,
})

export const deleteReport = (id) => ({
  type: DELETE_REPORT,
  payload: id,
})

