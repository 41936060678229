import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const useTableData = (actionCreator, initialState) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const urlUserId = query.get('userId');
  const urlEventId = query.get('eventId');

  const { loading, tableData } = useSelector(state => state[initialState.dataKey]);
  const {
    data,
    totalItems,
    totalPages,
    pageSize,
    pageIndex,
    filters,
    lastVisible,
  } = tableData;
  const sortBy = tableData.sortBy;

  const fetchNewData = useCallback((props = {}) => {
    let params = attachFilters(props);

    if (params.filters) {
      let newFilters = {};

      params.filters.map((filter) => {
        newFilters[filter.id] = filter.value;
      });

      params.filters = newFilters;

      // TODO: handle different filters here, chatId for example is is chatId exist
      // If filters is applied, remove the sorting
    }

    dispatch(actionCreator(params));
  }, []);

  // useEffect(() => {
  //   fetchNewData();
  // }, []);

  useEffect(() => {
    if (urlUserId) {
      updateFilters('userId', urlUserId);
    }
  }, [urlUserId]);

  useEffect(() => {
    if (urlEventId) {
      updateFilters('eventId', urlEventId);
    }
  }, [urlEventId]);

  const setPageSize = (size) => {
    fetchNewData({ pageSize: size, pageIndex: 0 });
  }

  const setPageIndex = (index, pageSize) => {
    fetchNewData({ pageIndex: index, lastVisible, pageSize });
  }

  const setSortBy = (sort) => {
    let sortOrder =  -1;
    let newSortProps = { 'createdAt': -1 };

    if (sort[0]) {
      sortOrder = sort[0] && sort[0].desc ? -1 : 1;
      newSortProps = { [sort[0].id]: sortOrder };
    } else if (filters.length > 0) {
      newSortProps = {};
    }

    fetchNewData({ sortBy: newSortProps });
  }

  const debouncedSetFilters = debounce((filters) => {
    return setFilters(filters)
  }, 500);

  const processDebounce = (filters) => {
    // if last added filter is email, name or id, then debounce, if not, not
    if (filters.length > 0) {
      const lastFilter = filters[filters.length - 1];
      const filterId = lastFilter && lastFilter.id;
      const filterBounced = ['email', 'firstName', 'id', 'lastName'];

      if (filterBounced.includes(filterId)) {
        debouncedSetFilters(filters);
      } else {
        setFilters(filters);
      }
    } else {
      setFilters([]);
    }
  }

  const setFilters = (filters) => {
    fetchNewData({ filters });
  }

  const attachFilters = (params) => {
    const { pageSize, pageIndex, sortBy, filters } = params;
    const newFilters = {};

    if (filters && filters.length > 0) {
      filters.map((filter) => {
        newFilters[filter.id] = filter.value;
      })
    }

    return {
      ...params,
      ...(newFilters[0] && Object.keys(newFilters[0].length > 0) && { filters: newFilters }),
      ...(pageSize !== undefined && pageSize > 100 && { pageSize }),
      ...(pageIndex >= 0 && { pageIndex }),
      ...(sortBy && sortBy.length > 0 && { sortBy }),
    };
  }

  const updateFilters = (columnId, value) => {
    // Extract here the value and then pass it down to filters
    const newFilters = filters.map((filter) => {
      if (filter.id === columnId) {
        return { id: columnId, value };
      }
      return filter;
    });
    setFilters(newFilters);
  };

  return {
    data,
    totalItems,
    loading,
    filters,
    pageSize,
    pageIndex,
    totalPages,
    sortBy,
    setFilters: processDebounce,
    setPageSize,
    setPageIndex,
    setSortBy,
    refreshData: fetchNewData,
    updateFilters
  };
};

export default useTableData;