import React, { useEffect, useRef } from 'react';
import { Input, Col } from 'reactstrap';
import { appLanguages } from '../../common/languages';

const genders = ['male', 'female'];

const Filter = React.forwardRef(({ column }, ref) => {
  return (
    <div ref={ref} style={{ marginTop: 5 }}>
      {column.canFilter && column.render('Filter')}
    </div>
  );
});

Filter.displayName = 'Filter';

export { Filter };

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value);
      }}
      placeholder={`search (${length}) ...`}
    />
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, onFilterChange, preFilteredRows, id }
}) => {
  const options = React.useMemo(() => {
    if (id === 'status') {
      return ['cancelled', 'expired', 'active'];
    } else if (id === 'language' || id === 'lang') {
      return appLanguages;
    } else if (id === 'gender') {
      return genders;
    } else {
      const options = new Set();
      if (preFilteredRows && preFilteredRows.length > 0) { // Check if preFilteredRows exists
        preFilteredRows.forEach((row) => {
          if (row.values[id] != null) { // Check if the value is not null or undefined
            options.add(row.values[id]);
          }
        });
      }
      return [...options.values()];
    }
  }, [id, preFilteredRows]);

  const handleChange = (e) => {
    setFilter(e.target.value || undefined);
  };

  return (
    <select
      id='custom-select'
      className="form-select"
      value={filterValue || ''}
      onChange={handleChange}
    >
      <option value=''>All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export function filterForExactMatch(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue !== undefined ? String(rowValue) === String(filterValue) : true;
  });
}

export const SelectColumnPositiveFilter = ({
  column: { filterValue, setFilter },
}) => {
  const handleChange = (e) => {
    setFilter(e.target.value || undefined);
  };

  return (
    <select
      id='custom-select'
      className="form-select"
      value={filterValue || ''}
      onChange={handleChange}
    >
      <option value=''>All</option>
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  );
};

export function filterForPositiveNumber(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    // Check if the row value indicates a positive number
    const isPositive = rowValue > 0;

    // Filter logic based on the filterValue
    if (filterValue === "Yes") {
      return isPositive; // Show rows where the value is positive
    } else if (filterValue === "No") {
      return !isPositive; // Show rows where the value is not positive
    }

    return true; // If filterValue is 'All' or undefined, show all rows
  });
}

export function filterForExisItem(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    const exist = !!rowValue;

    if (filterValue === "Yes") {
      return exist;
    } else if (filterValue === "No") {
      return !exist;
    }

    return true;
  });
}

export const EventsFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  return (
    <select
      id='custom-select'
      className="form-select"
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value || undefined)}
    >
      <option value=''>All</option>
      <option value="some">Some</option>
      <option value="none">None</option>
    </select>
  );
};

export function filterEvents(rows, id, filterValue) {
  return rows.filter(row => {
    const events = row.values[id];
    switch (filterValue) {
      case "some":
        return events.length > 0;
      case "none":
        return events.length === 0;
      default:
        return true;
    }
  });
}

export const IsActiveFilter = ({ column: { filterValue, setFilter } }) => {
  const handleChange = e => {
    setFilter(e.target.value || undefined);
  };

  return (
    <select
      id='custom-select'
      className="form-select"
      value={filterValue || ''}
      onChange={handleChange}
    >
      <option value=''>All</option>
      <option value="true">Yes</option>
      <option value="false">No</option>
    </select>
  );
};

export function filterIsActive(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = !!row.values[id];
    return filterValue === 'true' ? rowValue === true : filterValue === 'false' ? rowValue === false : true;
  });
}

export const NoneSomeFilter = ({ column: { filterValue, setFilter, id } }) => {
  const handleChange = e => {
    setFilter(e.target.value || undefined);
  };

  return (
    <select
      onChange={handleChange}
      value={filterValue || ''}
      className="form-select"
      id={id}
    >
      <option value="">All</option>
      <option value="some">Some</option>
      <option value="none">None</option>
    </select>
  );
};

export function filterNoneSome(rows, id, filterValue) {
  console.log('filterValue', filterValue, id, rows);
  if (filterValue === 'some') {
    return rows.filter(row => row.values[id] > 0);
  }
  if (filterValue === 'none') {
    return rows.filter(row => row.values[id] === 0);
  }
  return rows;
}