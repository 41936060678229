import React from 'react';

export const ShortenedIdCell = ({ value }) => {
  const shortened = value.length > 10 ? `${value.slice(0, 5)}...${value.slice(-5)}` : value;

  const copyToClipboard = () => navigator.clipboard.writeText(value);

  return (
    <div onClick={copyToClipboard} title="Click to copy" style={{ cursor: 'pointer', maxWidth: '120px' }}>
      {shortened}
      <span style={{ marginLeft: '5px', color: 'blue' }}>📋</span>
    </div>
  );
};

export const StatusCell = ({ value }) => {
  const isTrue = value === 'active' || typeof value === 'boolean' && value === true;
  let background = isTrue ? 'lightgreen' : 'none';
  if (typeof value === 'string') {
    background = value === 'active' || value === 'True' ? 'lightgreen' : value === 'cancelled' ? 'orange' : 'lightblue';
  } else {
    background = value ? 'lightgreen' : 'lightcoral';
  }

  return (
    <div style={{
      backgroundColor: background,
      // color: isTrue ? 'white' : 'inherit',
      textAlign: 'center',
      padding: '5px',
      borderRadius: '5px',
      maxWidth: '100px',
  }}>
      {value}
    </div>
  );
};

export const ThinCell = ({ value }) => (
  <div style={{
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }}>
    {value}
  </div>
);

export const HighlightCell = (condition, backgroundColor, text) => (
  <div style={{
    backgroundColor: condition > 0 ? backgroundColor : 'none',
    color: condition > 0 ? 'white' : 'inherit',
    textAlign: 'center',
    padding: '5px',
    borderRadius: '5px',
  }}>
    {text}
  </div>
);

export const GenderCell = ({ value }) => {
  const backgroundColor = value === 'female' ? '#FFC0CB' : value === 'male' ? '#ADD8E6' : '';
  const textColor = value === 'male' ? 'grey' : 'black';

  return (
    <div style={{
      backgroundColor,
      textAlign: 'center',
      color: textColor,
      padding: '5px',
      borderRadius: '5px'
    }}>
      {value}
    </div>
  );
};
