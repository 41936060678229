import {
  GET_FEEDBACK,
  GET_FEEDBACK_FAIL,
  GET_FEEDBACK_SUCCESS,
  TOGGLE_LOADING,
  DELETE_FEEDBACK,
} from "./actionTypes"

export const getFeedback = (params) => ({
  type: GET_FEEDBACK,
  payload: params,
})

export const getFeedbackSuccess = data => ({
  type: GET_FEEDBACK_SUCCESS,
  payload: data,
})

export const getFeedbackFail = error => ({
  type: GET_FEEDBACK_FAIL,
  payload: error,
})

export const toggleLoading = () => ({
  type: TOGGLE_LOADING,
})

export const deleteFeedback = (id) => ({
  type: DELETE_FEEDBACK,
  payload: id,
})
