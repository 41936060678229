/* EVENTS */
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const ADD_NEW_EVENT = "ADD_NEW_EVENT";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL";

export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";

export const TOGGLE_LOADING = "TOGGLE_LOADING";

export const GET_EVENT_BY_ID = "GET_EVENT_BY_ID";
export const GET_EVENT_BY_ID_SUCCESS = "GET_EVENT_BY_ID_SUCCESS";
export const GET_EVENT_BY_ID_FAIL = "GET_EVENT_BY_ID_FAIL";

export const CLEAR_EVENTS_ERROR = "CLEAR_EVENTS_ERROR";
