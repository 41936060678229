import React from "react";
import { ShortenedIdCell } from '../../components/Common/Cells';
import { Link } from 'react-router-dom';
import { formatDate } from "../../utils/dates";
import { getReports, deleteReport } from "../../store/reports/actions";
import withList from '../HOC/withList';
import DeleteButton from '../../components/Common/DeleteButton';

const createColumns = () => [
  {
    Header: 'From User',
    accessor: 'fromUserId',
    Cell: ({ value }) => value ? (
      <Link to={`/users/${value}`} style={{ textDecoration: 'none' }}>
        {ShortenedIdCell({ value })}
      </Link>
    ) : null,
  },
  {
    Header: 'To User',
    accessor: 'toUserId',
    Cell: ({ value }) => value ? (
      <Link to={`/users/${value}`} style={{ textDecoration: 'none' }}>
        {ShortenedIdCell({ value })}
      </Link>
    ) : null,  // Assuming you have a toUserId field
  },
  {
    Header: 'To Event',
    accessor: 'toEventId',
    Cell: ({ value }) => value ? (
      <Link to={`/events/${value}`} style={{ textDecoration: 'none' }}>
        {ShortenedIdCell({ value })}
      </Link>
    ) : null,
  },
  {
    Header: 'Content',
    accessor: 'description',
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => formatDate(new Date(value).getTime()),
  },
  {
    Header: 'Delete',
    accessor: '_id',
    Cell: ({ value }) => <DeleteButton value={value} action={deleteReport} />,
  }
];

export default withList(getReports, 'reports', createColumns);
