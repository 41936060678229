import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  TOGGLE_LOADING,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  CLEAR_USERS_ERROR,
} from "./actionTypes"
import { tableDefaultState } from "../common/misc.js"

const INIT_STATE = {
  tableData: tableDefaultState,
  userProfile: {},
  userData: {},
  error: {},
  loading: false,
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        tableData: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          data: [...state.tableData.data, action.payload],
        }
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          data: state.tableData.data.map(user =>
            user.id.toString() === action.payload.id.toString()
              ? { user, ...action.payload }
              : user
          ),
        },
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          data: state.tableData.data.filter(
            user => user.id.toString() !== action.payload.toString()
          ),
        },
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      }

    case GET_USER_BY_ID:
      return {
        ...state,
        userData: {},
      }

    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      }

    case GET_USER_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CLEAR_USERS_ERROR:
      return {
        ...state,
        error: {},
      }

    default:
      return state
  }
}

export default users
