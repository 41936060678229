import React, { useEffect, useMemo } from "react";
import TableContainer from '../../components/Common/TableContainer';
import useTableData from '../../hooks/useTableData';
import { useNavigate } from 'react-router-dom';

const withList = (fetchData, dataKey, createColumns, path) => {
  return function WrappedComponent() {
    const {
      data,
      loading,
      filters,
      pageSize,
      pageIndex,
      totalPages,
      totalItems,
      sortBy,
      setPageSize,
      setPageIndex,
      setSortBy,
      setFilters,
      refreshData,
    } = useTableData(fetchData, { dataKey });
    const navigate = useNavigate();
    const columns = useMemo(createColumns, []);

    // useEffect(() => {
    //   const handleFocus = () => {
    //     refreshData();
    //   };
    //   window.addEventListener('focus', handleFocus);
    //   return () => window.removeEventListener('focus', handleFocus);
    // }, []);
console.log('path', pageSize);
    return (
      <div className="page-content">
        <TableContainer
          columns={columns}
          data={data}
          isGlobalFilter={true}
          refreshData={refreshData}
          isLoading={loading}
          isAddOptions={false}
          pageSize={pageSize}
          className="custom-header-css"
          setFilters={setFilters}
          setPageSize={setPageSize}
          setPageIndex={setPageIndex}
          setSortBy={setSortBy}
          sortBy={sortBy}
          filters={filters}
          pageIndex={pageIndex}
          totalPages={totalPages}
          totalItems={totalItems}
          {...(path && { onRowClick: row => {
            console.log('row', row);
            return navigate(`${path}/${row}`)
          }})}
        />
      </div>
    );
  };
};

export default withList;