import { call, put, takeEvery } from "redux-saga/effects"

import { GET_FEEDBACK, DELETE_FEEDBACK } from "./actionTypes"

import {
  getFeedbackSuccess,
  getFeedbackFail,
  toggleLoading,
} from "./actions"

//Include Both Helper File with needed methods
import { getFeedback } from "../../helpers/reports_helper"

function* fetchFeedback({ payload }) {
  yield put(toggleLoading())
  try {
    const response = yield call(getFeedback, payload)
    yield put(getFeedbackSuccess(response))
  } catch (error) {
    yield put(getFeedbackFail(error))
  }
  yield put(toggleLoading())
}

function* feedbackSaga() {
  yield takeEvery(GET_FEEDBACK, fetchFeedback)
  yield takeEvery(DELETE_FEEDBACK, fetchFeedback)
}

export default feedbackSaga;
