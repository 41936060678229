import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '../../components/Common/Loading';
import { clearUsersError } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { getUserById } from '../../store/users/actions';
import { formatDate } from '../../utils/dates';
import { imgAWSPath } from '../../utils/images';

function User() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, userData, error } = useSelector(state => state.users);
  const [formattedData, setFormattedData] = useState({});

  useEffect(() => {
    if (!loading && (!userData._id || userData._id !== id)) {
      dispatch(getUserById(id));
    }
  }, [userData, id]);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setFormattedData(formatData(userData));
    }
  }, [userData]);

  const formatData = (data) => {
    return {
      Information: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        _id: data._id,
        uid: data.uid,
        lang: data.lang,
        languages: data.languages,
        createdAt: data.createdAt,
        createdAtISODate: data.createdAtISODate,
        updatedAt: data.updatedAt,
        updatedAtISODate: data.updatedAtISODate,
        device: data.device,
        lastLogin: data.lastLogin,
        lastLoginISODate: data.lastLoginISODate,
      },
      Social: {
        blockedBy: data.blockedBy,
        blockedUsers: data.blockedUsers,
        followedBy: data.followedBy,
        following: data.following,
      },
      Status: {
        isActive: data.isActive,
        isActiveExpirationDate: data.isActiveExpirationDate,
        onboardingCompleted: data.onboardingCompleted,
        loggedIn: data.loggedIn,
      },
      Pictures: {
        profileImages: data.profileImages.map(imgAWSPath),
      },
      Other: {
        latitude: data.location ? data.location.coordinates[1] : null,
        longitude: data.location ? data.location.coordinates[0] : null,
        notifications: data.notifications,
        pushTokens: data.pushTokens,
        sendNotificationOnNewNearEvent: data.sendNotificationOnNewNearEvent,
        events: data.events,
        interests: data.interests,
      },
    };
  };

  useEffect(() => {
    if (error && Object.keys(error).length > 0 && error.message) {
      setTimeout(() => {
        console.log('clearing error', error);
        dispatch(clearUsersError());
      }, 5000);
    }
  }, [error]);

  if (error && Object.keys(error).length > 0) {
    return <div>Error: {error.message}</div>;
  }

  if (loading || !userData || !userData._id || !Object.keys(formattedData).length) {
    return <Loading />;
  }

  return (
    <div className="data">
      <h1 className="data-title">User Data</h1>
      <div className="data-container">
        {Object.entries(formattedData).map(([section, values]) => (
          <div
            key={section}
            className="data-box">
            <h2>{section}</h2>
            {Object.entries(values).map(([key, value]) => (
              <div key={key} className={`data-row ${section === 'Pictures' ? 'data-row-items' : ''}`}>
                <strong className='data-key'>{key}: </strong>
                {key === 'pictures' || key === 'profileImages'
                  ? <div>{value.map((imgUrl, index) => (
                      <img key={index} src={imgUrl} alt="" className='data-user-image' />
                    ))}</div>
                  : typeof value === 'object' && value !== null
                    ? <div className='data-subgroup'>
                        {Object.entries(value).map(([subKey, subValue]) => (
                          <p key={subKey}><strong>{subKey}:</strong> {subValue}</p>
                        ))}
                      </div>
                    : key === 'createdAt' || key === 'updatedAt'
                      ? formatDate(value)
                      : <p>{value}</p>
                }
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default User;