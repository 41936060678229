import flag_en from '../assets/images/flags/en.svg';
import flag_es from '../assets/images/flags/es.svg';
import flag_fr from '../assets/images/flags/fr.svg';
import flag_de from '../assets/images/flags/de.svg';
import flag_pt from '../assets/images/flags/pt.svg';
import flag_ca from '../assets/images/flags/ca.png';
import flag_du from '../assets/images/flags/du.svg';
import flag_it from '../assets/images/flags/it.svg';

export const langFlags = {
  en: flag_en,
  es: flag_es,
  fr: flag_fr,
  de: flag_de,
  pt: flag_pt,
  ca: flag_ca,
  zh: flag_ca,
  it: flag_it,
  du: flag_du,
};

export const countryFlags = {
  en: flag_en,
  es: flag_es,
  fr: flag_fr,
  de: flag_de,
  pt: flag_pt,
  ca: flag_ca,
  it: flag_it,
  du: flag_du,
  zh: flag_ca,
};