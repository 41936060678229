import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import events from "./events/reducer";
import users from "./users/reducer";
import reports from "./reports/reducer";
import feedback from "./feedback/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  Profile,
  events,
  users,
  reports,
  feedback
});

export default rootReducer;
