import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = "https://t12khapmye.execute-api.eu-west-3.amazonaws.com/";
// const API_URL = "http://localhost:3000/";

export const axiosApi = axios.create({
  baseURL: API_URL,
});

// export const axiosApi = axios.create({
//   baseURL: API_URL,
//   withCredentials: true,
// });

axiosApi.defaults.headers.common["sc-uxr-jtkew"] = token;
axiosApi.defaults.headers.common["Accept"] = "*/*";

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  console.log('url', url);
  return await axiosApi
    .get(url, { ...config })
    .then((response) => {
      return response.data
    });
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => {
  return response.data
    }).catch((error) => console.log("error", error));
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  console.log('url', url);
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
