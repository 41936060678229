import { call, put, takeEvery } from "redux-saga/effects"

import { GET_REPORTS, DELETE_REPORT } from "./actionTypes"

import {
  getReportsSuccess,
  getReportsFail,
  toggleLoadingReports,
} from "./actions"

import { getReports } from "../../helpers/reports_helper"

function* fetchReports({ payload }) {
  yield put(toggleLoadingReports())
  try {
    const response = yield call(getReports, payload)
    yield put(getReportsSuccess(response))
  } catch (error) {
    yield put(getReportsFail(error))
  }
  yield put(toggleLoadingReports())
}

// function* deleteReport({ payload }) {
//   console.log('payload', payload);
//   yield put(toggleLoadingReports())
//   try {
//     const responseDelete = yield call(deleteReport, payload)

//     if (responseDelete.status === 200) {
//       const response = yield call(getReports)
//       yield put(getReportsSuccess(response))
//     }
//   } catch (error) {
//     yield put(getReportsFail(error))
//   }
//   yield put(toggleLoadingReports())
// }

function* reportsSaga() {
  yield takeEvery(GET_REPORTS, fetchReports)
  yield takeEvery(DELETE_REPORT, fetchReports)
}

export default reportsSaga;
