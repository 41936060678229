//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/admin/login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const POST_LOGIN = "/e/admin/login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

// EVENTS
export const GET_EVENTS = "/e/events";
export const ADD_NEW_EVENT = "/e/events";
export const UPDATE_EVENT = "/e/events";
export const DELETE_EVENT = "/e/events";

// USERS
export const GET_USERS = "/e/users";
export const ADD_NEW_USER = "/e/users";
export const GET_USER_PROFILE = "/e/users";
export const UPDATE_USER = "/e/users";
export const DELETE_USER = "/e/users";

// REPORTS
export const GET_REPORTS = "/e/reports";
export const DELETE_REPORT = "/e/reports";

// FEEDBACK
export const GET_FEEDBACK = "/e/feedback";
export const DELETE_FEEDBACK = "/e/feedback";

export const GET_USER_BY_ID = "/e/users";
export const GET_EVENT_BY_ID = "/e/events";
