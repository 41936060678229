import { takeEvery, put, call } from "redux-saga/effects"

import {
  ADD_NEW_EVENT,
  DELETE_EVENT,
  GET_EVENTS,
  UPDATE_EVENT,
  GET_EVENT_BY_ID,
} from "./actionTypes"

import {
  getEventsSuccess,
  getEventsFail,
  addEventFail,
  addEventSuccess,
  updateEventSuccess,
  updateEventFail,
  deleteEventSuccess,
  deleteEventFail,
  toggleEventsLoading,
  getEventByIdSuccess,
  getEventByIdFail,
} from "./actions"

import {
  getEvents,
  addNewEvent,
  updateEvent,
  deleteEvent,
  getEventById,
} from "../../helpers/events_helper"

function* fetchEvents({ payload }) {
  yield put(toggleEventsLoading())
  try {
    const response = yield call(getEvents, payload)
    yield put(getEventsSuccess(response))
  } catch (error) {
    yield put(getEventsFail(error))
  }
  yield put(toggleEventsLoading())
}

function* onAddNewEvent({ payload: event }) {
  try {
    const response = yield call(addNewEvent, event)
    yield put(addEventSuccess(response))
  } catch (error) {
    yield put(addEventFail(error))
  }
}

function* onUpdateEvent({ payload: event }) {
  try {
    const response = yield call(updateEvent, event)
    yield put(updateEventSuccess(response))
  } catch (error) {
    yield put(updateEventFail(error))
  }
}

function* onDeleteEvent({ payload: event }) {
  try {
    const response = yield call(deleteEvent, event)
    yield put(deleteEventSuccess(response))
  } catch (error) {
    yield put(deleteEventFail(error))
  }
}

function* fetchEventById({ payload }) {
  yield put(toggleEventsLoading())
  try {
    const response = yield call(getEventById, payload)
    yield put(getEventByIdSuccess(response))
  } catch (error) {
    yield put(getEventByIdFail(error))
  }
  yield put(toggleEventsLoading())
}

function* eventsSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents)
  yield takeEvery(ADD_NEW_EVENT, onAddNewEvent)
  yield takeEvery(UPDATE_EVENT, onUpdateEvent)
  yield takeEvery(DELETE_EVENT, onDeleteEvent)
  yield takeEvery(GET_EVENT_BY_ID, fetchEventById)
}

export default eventsSaga
