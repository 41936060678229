import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';

const DeleteButton = ({ value, action }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useDispatch();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const confirmAction = () => {
    dispatch(action(value));
    closeModal();
  };

  return (
    <>
      <button onClick={openModal} className="deleteButton">
        <i className="bx bx-trash"></i>
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Confirmation Modal"
        className="modal"
      >
        <h2 className="modal-title">Are you sure you want to delete this item?</h2>
        <div className="modal-buttons">
          <button onClick={closeModal} className="modal-button">No</button>
          <button onClick={confirmAction} className="modal-button">Yes</button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteButton;