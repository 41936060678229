import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../components/Common/Loading';
import { clearEventsError } from '../../store/actions';
import { getEventById } from '../../store/events/actions';
import { getUserById } from '../../helpers/users_helper';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/dates';
import { imgAWSPath } from '../../utils/images';

function Event() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, eventData, error } = useSelector(state => state.events);
  const [formattedData, setFormattedData] = useState({});
  const [usersData, setUsersData] = useState({});
  const [usersLoading, setUsersLoading] = useState(true);

  useEffect(() => {
    if (eventData.users && Object.keys(eventData.users).length > 0 && !Object.keys(usersData).length) {
      const fetchUsersData = async () => {
        setUsersLoading(true);
        const newUsersData = {};
        const userLists = ['waitList', 'active', 'blocked', 'leftOut'];
        for (const list of userLists) {
          for (const user of eventData.users[list]) {
            if (!newUsersData[list]) {
              newUsersData[list] = {};
            }

            if (user && user._id) {
              try {
                const userId = user._id;
                console.log('Fetching user data for userId:', userId);
                const userData = await getUserById(userId);
                newUsersData[list][userId] = userData;
              } catch (error) {
                console.error(`Failed to fetch user data for user with id ${userId}:`, error);
              }
            }
          }
        }
        setUsersData(newUsersData);
        setUsersLoading(false);
      };
      fetchUsersData();
    }
  }, [eventData, usersData]);

  useEffect(() => {
    if (!loading && (!eventData._id || eventData._id !== id)) {
      dispatch(getEventById(id));
    }
  }, [dispatch, eventData, id, loading]);

  useEffect(() => {
    if (eventData && Object.keys(eventData).length > 0) {
      setFormattedData(formatData(eventData));
    }
  }, [eventData]);

  const formatData = (data) => {
    return {
      Information: {
        amountOfUsers: data.amountOfUsers,
        title: data.title,
        description: data.description,
        _id: data._id,
      },
      Users: data.users,
      Location: {
        coordinates: data.location ? data.location.coordinates : {},
        city: data.city,
      },
      Image: {
        chatBGImg: data.chatBGImg,
        authorLink: data.chatBGImgData ? data.chatBGImgData.authorLink : '',
        authorName: data.chatBGImgData ? data.chatBGImgData.authorName : '',
        description: data.chatBGImgData ? data.chatBGImgData.description : '',
        location: data.chatBGImgData ? data.chatBGImgData.location : '',
        unsplashLink: data.chatBGImgData ? data.chatBGImgData.unsplashLink : '',
      },
      Status: {
        status: data.status,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
      },
    };
  };

  useEffect(() => {
    if (error && Object.keys(error).length > 0 && error.message) {
      setTimeout(() => {
        console.log('clearing error', error);
        dispatch(clearEventsError());
      }, 5000);
    }
  }, [dispatch, error]);

  if (error && Object.keys(error).length > 0) {
    return <div>Error: {error.message}</div>;
  }

  if (loading || !eventData._id) {
    return <Loading />;
  }

  return (
    <div className="data">
      <h1 className="data-title">Event Data</h1>
      <div className="data-container">
        {Object.entries(formattedData).map(([section, values]) => (
          <div
            key={section}
            className="data-box"
            style={section === "Image" && formattedData.Image.chatBGImg ? {
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${formattedData.Image.chatBGImg})`,
              backgroundSize: 'cover',
              color: 'white',
              maxWidth: 300,
            } : {}}>
            <h2>{section}</h2>
            {Object.entries(values).map(([key, value]) => (
              <div key={key} className={`data-row ${section === 'Users' ? 'data-row-items' : ''}`}>
                <strong className='data-key'>{key}: </strong>
                {typeof value === 'object' && value !== null
                  ? <div className='data-subgroup'>
                      {console.log('Rendering subgroup', { key, value, usersData: usersData[key] })}
                      {Object.entries(value).map(([subKey, subValue]) => (
                        <React.Fragment key={subKey}>
                          {section === 'Users' && usersData[key] && usersData[key][subValue._id]
                            ? <Link to={`/user/${usersData[key][subValue._id]._id}`} className={`data-user-box ${usersData[key][subValue._id].gender}`}>
                                <div>
                                  <img src={imgAWSPath(subValue.profileImage)} alt={usersData[key][subValue._id].firstName} className='data-user-picture' />
                                </div>
                                <div>
                                  <p>{usersData[key][subValue._id].firstName} {usersData[key][subValue._id].lastName}</p>
                                </div>
                              </Link>
                            : <div key={subKey}>{usersLoading ? <Loading /> : `${subValue.firstName} ${subValue.lastName}`}</div>
                          }
                        </React.Fragment>
                      ))}
                    </div>
                  : key === 'chatBGImg' || key === 'unsplashLink' || key === 'authorLink'
                    ? <a href={value} target="_blank" rel="noopener noreferrer">Link 🔗</a>
                    : key === 'createdAt' || key === 'updatedAt'
                    ? formatDate(value)
                    : key === 'city'
                    ? <a href={`https://www.google.com/maps/search/?api=1&query=${value}`} target="_blank" rel="noopener noreferrer">{value}</a>
                    : <p>{value}</p>
                }
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Event;
