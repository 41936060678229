import {
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAIL,
  TOGGLE_LOADING,
} from "./actionTypes"
import { tableDefaultState } from "../common/misc.js"

const INIT_STATE = {
  tableData: tableDefaultState,
  error: {},
  loading: false,
}

const feedback = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        tableData: action.payload,
      }

    case GET_FEEDBACK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      }

    default:
      return state
  }
}

export default feedback
