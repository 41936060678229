import React, { useMemo } from "react";
import TableContainer from '../../components/Common/TableContainer';
import useTableData from '../../hooks/useTableData';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/dates';
import { getUsers } from "../../store/actions";
import {
  SelectColumnFilter,
  filterForExactMatch,
  filterEvents,
  EventsFilter,
  IsActiveFilter,
  filterIsActive,
} from '../../components/Common/filters';
import { HighlightCell, GenderCell } from '../../components/Common/Cells';
import { countryFlags } from '../../common/flags';

const Users = () => {
    const {
      data,
      loading,
      filters,
      pageSize,
      pageIndex,
      totalPages,
      totalItems,
      sortBy,
      setPageSize,
      setPageIndex,
      setSortBy,
      setFilters,
      refreshData,
    } = useTableData(getUsers, { dataKey: 'users' });
    const navigate = useNavigate();
    const columns = useMemo(
      () => [
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Language',
        accessor: 'lang',
        Filter: SelectColumnFilter,
        filter: filterForExactMatch,
        Cell: ({ value }) => {
          return (
            <img
              src={countryFlags[value]}
              alt={value}
              style={{
                width: '20px',
                height: '20px'
              }} />
          )
        }
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        Cell: GenderCell,
        Filter: SelectColumnFilter,
        filter: filterForExactMatch,
      },
      {
        Header: 'City',
        accessor: 'currentCity',
        Filter: SelectColumnFilter,
        filter: filterForExactMatch,
      },
      {
        Header: 'Country',
        accessor: 'currentCountry',
        Filter: SelectColumnFilter,
        filter: filterForExactMatch,
      },
      {
        Header: 'Events Created',
        accessor: 'events',
        Cell: ({ value }) => HighlightCell(value.length, 'green', value.length),
        Filter: EventsFilter,
        filter: filterEvents,
      },
      {
        Header: 'Is active',
        accessor: 'isActive',
        Cell: ({ value }) => HighlightCell(value, 'green', value ? 'Yes' : 'No'),
        Filter: IsActiveFilter,
        filter: filterIsActive,
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: ({ value }) => <div className="single-line">{formatDate(new Date(value).getTime())}</div>,
        Filter: () => null,
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        Cell: ({ value }) => <div className="single-line">{formatDate(new Date(value).getTime())}</div>,
        Filter: () => null,
      }
    ]);

    return (
      <div className="page-content">
        <TableContainer
          className="custom-header-css"
          columns={columns}
          data={data}
          isGlobalFilter={true}
          refreshData={refreshData}
          isLoading={loading}
          isAddOptions={false}
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          setFilters={setFilters}
          setPageSize={setPageSize}
          setPageIndex={setPageIndex}
          setSortBy={setSortBy}
          sortBy={sortBy}
          filters={filters}
          onRowClick={(userId) => {
            console.log(userId);
            navigate(`/user/${userId}`);
          }}
        />
      </div>
    );
  };

export default Users;