import React from "react";
import { ShortenedIdCell } from '../../components/Common/Cells';
import { Link } from 'react-router-dom';
import { formatDate } from "../../utils/dates";
import { getFeedback, deleteFeedback } from "../../store/feedback/actions";
import withList from '../HOC/withList';
import DeleteButton from '../../components/Common/DeleteButton';

const createColumns = () => [
  {
    Header: 'From User',
    accessor: 'fromUserId',
    Cell: ({ value }) => (
      <Link to={`/users/${value}`} style={{ textDecoration: 'none' }}>
        {ShortenedIdCell({ value })}
      </Link>
    ),
  },
  {
    Header: 'Content',
    accessor: 'description',
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => formatDate(new Date(value).getTime()),
    Filter: () => null,
  },
  {
    Header: 'Delete',
    accessor: '_id',
    Cell: ({ value }) => <DeleteButton value={value} action={deleteFeedback} />,
  }
];

export default withList(getFeedback, 'feedback', createColumns);
