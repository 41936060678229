import React from 'react';
import loadingGif from '../../assets/images/logo_loading.gif';

const LoadingIndicator = ({ width = '80px', message = '' }) => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    }}>
      <div style={{ textAlign: 'center' }}>
        <img src={loadingGif} alt="Loading..." style={{ width }} />
        {message && <p style={{ marginTop: '10px' }}>{message}</p>}
      </div>
    </div>
  );
};

export default LoadingIndicator;
